<template>
  <div>
    <!--begin::User-->
    <div>

      <div class="pb-5 d-flex justify-content-between">
        <h3 class="card-label">
          {{ $t('admin_account.edit') }}
          <span class="text-muted pt-2 font-size-sm d-block"></span>
        </h3>
      </div>
      <div class="row mt-5">
        <div class="col-md-12">
            <div class="row">
        <div class="col-md-12">
          <div class="card card-custom">
            <div class="card-body row">
                <div class="col-lg-12 mb-5">
                  <label>{{ $t('admin_account.email') }}</label>
                    <input type="email" class="form-control" :class="validation && validation.email ? 'is-invalid' : ''" v-model="email" :placeholder="$t('admin_account.email')"/>
                    <span v-if="validation && validation.email" class="fv-plugins-message-container invalid-feedback">
                        {{ validation.email[0] }}
                    </span>
                </div>
                <div class="col-lg-12 mb-5">
                  <label>{{ $t('admin_account.password') }}</label>
                  <div class="input-group">
                    <input type="password" class="form-control" :class="validation && validation.password ? 'is-invalid' : ''" v-model="password" :placeholder="$t('admin_account.password')"/>
                    <span v-if="validation && validation.password" class="fv-plugins-message-container invalid-feedback">{{ validation.password[0] }}</span>
                  </div>
                </div>

            </div>
          </div>
        </div>
        
        
      </div>
          
        </div>
      </div>


      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Vue from "vue";

export default {
  name: "from-periods",
  data() {
    return {
      mainRoute: 'admin_accounts',
    //   data: {
            email: '',
            password: '',
        // },
      isEditing: false,
      validation: null,
    };
  },

  methods: {


    save() {

      ApiService.post(this.mainRoute, {
            email: this.email,
            password: this.password,
        }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        // this.getData();
      }).catch((error) => {
        Vue.prototype.$postStatus = true;
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    //
    // update() {
    //
    //     ApiService.put(this.mainRoute + '/' + this.idEdit, {
    //         customers: this.data_customers,
    //     }).then((response) => {
    //         this.validation = null;
    //         this.$successAlert(response.data.message);
    //     }).catch((error) => {
    //         this.validation = error.response ? error.response.data.errors : null;
    //     });
    // },

    // async getData() {
    //   await ApiService.get(this.mainRoute).then((response) => {
    //     this.data = response.data.data;
    //   });
    // },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.admin_accounts")}]);

    // this.getData();

  },
};
</script>


